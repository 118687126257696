import { LanguageSignal } from 'components/languageSelector/LanguageSelector'
import { Button } from 'components'
import { ButtonSize } from 'components/button/Button'
import { ReactElement, useState } from 'react'
import { navigateTo, Pages } from 'services/routes'

export default function StepSelect(): ReactElement {
  const [step, setStep] = useState(undefined)

  const selectStep = (number: number) => () => {
    setStep(number)
  }

  const handleStep = () => {
    navigateTo(Pages.anonymize)
  }

  return (
    <>
      <h3 className="text-2xl font-extrabold text-black">
        {LanguageSignal.value.terms.Pages.StepSelection.selectStep}
      </h3>
      <div className="flex flex-col gap-4">

        <label htmlFor="collect" className="cursor-not-allowed opacity-40">
          <input
            type="radio"
            id="collect"
            name="step"
            value="0"
            onChange={selectStep(0)}
            disabled
          />
          <span className="font-extrabold text-black">
            {` ${LanguageSignal.value.terms.Pages.StepSelection.collect}`}
          </span>
          <span className="italic text-[#A3ADC2]">
            {` (${LanguageSignal.value.terms.Pages.StepSelection.onlyCollect})`}
          </span>
        </label>

        <label htmlFor="format" className="cursor-not-allowed opacity-40">
          <input
            type="radio"
            id="format"
            name="step"
            value="1"
            disabled
            onChange={selectStep(1)}
          />
          <span className="font-extrabold text-black">
            {` ${LanguageSignal.value.terms.Pages.StepSelection.format}`}
          </span>
          <span className="italic text-[#A3ADC2]">
            {` (${LanguageSignal.value.terms.Pages.StepSelection.hasData})`}
          </span>
        </label>

        <label htmlFor="anonymize">
          <input
            type="radio"
            id="anonymize"
            name="step"
            value="2"
            onChange={selectStep(2)}
          />
          <span className="font-extrabold text-black">
            {` ${LanguageSignal.value.terms.Pages.StepSelection.anonymize}`}
          </span>
          <span className="italic text-[#A3ADC2]">
            {` (${LanguageSignal.value.terms.Pages.StepSelection.hasFormattedData})`}
          </span>
        </label>

      </div>

      <Button
        size={ButtonSize.medium}
        text={LanguageSignal.value.terms.Common.continue}
        disabled={step === undefined}
        onClick={handleStep}
      />

      <button
        className="font-extrabold text-black underline"
        type="button"
        onClick={() => navigateTo(Pages.actionSelect)}
      >
        {LanguageSignal.value.terms.Common.leave}
      </button>
    </>
  )
}

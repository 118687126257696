import { auth } from '@osrdata/app_core'
import '@preact/signals-react/auto'
import { terms } from 'assets/localization/terms'
import {
  Loader,
  ModalWrapper,
  SideMenu, ToastMessage,
  TopBar,
} from 'components'
import { DeniedPage, HomePage } from 'pages'
import { ReactElement, StrictMode, Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useRoles } from 'services'
import { useAppDispatch, useAppSelector } from 'utils'

import 'App.scss'
import { loadPyodideScript } from 'services/anonymization'
import { LanguageSignal } from 'components/languageSelector/LanguageSelector'

const APP_NAME = 'Indicateurs RH'

/** Externalize router logic in its own component
 * in order to use useLocation hook
 */
function Router() {
  // const location = useLocation()

  // useEffect(() => {
  //   // reset root signals when changing route
  //   ModalSignal.value = undefined
  //   ToastSignal.value = { ...ToastSignal.value, timeout: 0 }
  // }, [location.pathname])

  return (
    <Routes>
      <Route element={<HomePage />} path="/" />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default function App(): ReactElement {
  const dispatch = useAppDispatch()
  const { isLogged, isLoading } = useAppSelector(state => state.user)
  const { hasAccess, rolesLoaded } = useRoles()
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    setAccessDenied(rolesLoaded && isLogged && !hasAccess)
  }, [isLogged, isLoading, hasAccess, rolesLoaded])

  const renderApp = () => {
    if (isLoading) return <Loader message={LanguageSignal.value.terms.Common.loading} standalone />
    if (accessDenied) return <DeniedPage />

    // todo move when we have upload history ?
    loadPyodideScript()

    return (
      <>
        <Router />
        <ModalWrapper />
        <SideMenu />
      </>
    )
  }

  if (!isLoading && !isLogged) return null

  return (
    <StrictMode>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          {!isLoading && <TopBar enableSideMenu={false} appName={APP_NAME} hasAccess={!accessDenied} />}
          <div id="app">{renderApp()}</div>
          <ToastMessage />
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  )
}

import { ReactComponent as ClipIcon } from 'assets/icons/clip.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { Button } from 'components'
import { ButtonSize } from 'components/button/Button'
import { LanguageSignal } from 'components/languageSelector/LanguageSelector'
import { proccessedFilesSignal } from 'components/processView/ProcessView'
import { ReactElement } from 'react'
import { navigateTo, Pages } from 'services/routes'

export default function FileDownload(): ReactElement {
  const leavePage = () => {
    proccessedFilesSignal.value = undefined
    navigateTo(Pages.actionSelect)
  }

  return (
    <>
      <h3 className="text-2xl font-extrabold text-black">
        {LanguageSignal.value.terms.Pages.FileDownload.fileViewing}
      </h3>

      <div>
        {proccessedFilesSignal.value.map(file => (
          <div key={file.name} className="font-extrabold text-[#256EFF]">
            <a className="flex w-full justify-between " href={file.link} download={file.name}>
              <div className="flex max-w-[calc(100%-30px)] items-center ">
                <ClipIcon />
                <p title={file.name} className="w-full truncate pr-px ">
                  {file.name}
                </p>
              </div>
              <div>

                <DownloadIcon />
              </div>
            </a>
          </div>
        ))}
      </div>

      <div className="flex justify-center">
        <div className="w-4/5 [&>button]:w-full">
          <Button
            size={ButtonSize.medium}
            text={LanguageSignal.value.terms.Pages.FileDownload.upload}
            disabled
          />
        </div>
      </div>

      <button
        className="font-extrabold text-black underline"
        type="button"
        onClick={leavePage}
      >
        {LanguageSignal.value.terms.Common.leave}
      </button>
    </>
  )
}

import dgexLogo from 'assets/logos/logo-D2D-Icodev.svg'
import { SideMenuOpenedSignal } from 'components'
import LanguageSelector from 'components/languageSelector/LanguageSelector'
import { PORTAL_URL } from 'config/config'
import Burger from './burger/Burger'
import AccountMenu from './menu/MenuAccount'

import './TopBar.scss'

type Props = {
  appName: string;
  enableSideMenu: boolean;
  hasAccess: boolean;
}

export default function TopBar({ appName, enableSideMenu, hasAccess }: Props) {
  const menuHidden = !hasAccess || !enableSideMenu
  const toggleMenu = () => {
    SideMenuOpenedSignal.value = !SideMenuOpenedSignal.value
  }

  return (
    <header className={menuHidden ? 'no-menu' : ''}>
      {!menuHidden && <Burger opened={SideMenuOpenedSignal.value} action={toggleMenu} />}

      <div className="logos">
        <a className="flex-center" href={PORTAL_URL.url}>
          <img alt={appName} src={dgexLogo} />
        </a>
        <h1>{appName}</h1>
      </div>

      <div className="flex flex-row items-center gap-6">
        <LanguageSelector />

        <AccountMenu />
      </div>
    </header>
  )
}

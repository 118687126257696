import { LanguageSignal } from 'components/languageSelector/LanguageSelector'

export const ALLOWED_UPLOAD_TYPES = [
  // 'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
]
export const ALLOWED_EXTENSIONS = ['.xlsx']

export const MAX_UPLOAD_SIZE = 10485760 // 10 Mo

export const formatFileSize = (size: number) => {
  // todo language
  const units = ['bytes', 'kiloBytes', 'megaBytes', 'gigaBytes']
  let unitIndex = 0

  while (size >= 1024 && unitIndex < units.length - 1) {
    size = Math.floor(size / 1024)
    unitIndex += 1
  }

  return `${size} ${LanguageSignal.value.terms.Pages.Upload[units[unitIndex]]}`
}

export const isExcelFile = (file: File) => ALLOWED_UPLOAD_TYPES.includes(file.type)

export const isCorrectFile = (file: File) => isExcelFile(file) && file.size < MAX_UPLOAD_SIZE

export const validateFilelist = (files: File[]) => files.some(file => isCorrectFile(file))

export const mergeUniqueFiles = (array: File[], objArray: File[]) => {
  const newArray = [...array]

  objArray.forEach(newObj => {
    const exists = newArray.some(item => item.name === newObj.name
      && item.lastModified === newObj.lastModified && newObj.type === item.type)
    if (!exists) {
      newArray.push(newObj)
    }
  })

  return newArray
}

import { useEffect } from 'react'
import { navigateTo, Pages } from 'services/routes'

export default function HomePage() {
  useEffect(() => {
    navigateTo(Pages.actionSelect)
  }, [])
  return (
    <div />
  )
}

import { LanguageSignal } from 'components/languageSelector/LanguageSelector'
import { ModalSignal } from 'components'
import ActionSelect from 'components/actionSelect/ActionSelect'
import Anonymization from 'components/anonymization/Anonymization'
import ErrorsView from 'components/errorsView/ErrorsView'
import Feedback from 'components/feedback/Feedback'
import ProcessView from 'components/processView/ProcessView'
import FileDownload from 'components/fileDownload/FileDownload'
import StepSelect from 'components/stepSelect/StepSelect'

export enum Pages {
   stepSelect = 'stepSelect',
   errors = 'errors',
   errorsView = 'errorsView',
   anonymize = 'anonymize',
   actionSelect = 'actionSelect',
   processView = 'processView',
   fileDownload = 'fileDownload'
}

// todo move
export const navigateTo = (page: Pages) => {
  if (page === Pages.stepSelect) {
    ModalSignal.value = <StepSelect />
    return
  }
  if (page === Pages.anonymize) {
    ModalSignal.value = <Anonymization />
    return
  }
  if (page === Pages.errors) {
    ModalSignal.value = <Feedback type="error" />
    return
  }
  if (page === Pages.errorsView) {
    ModalSignal.value = <ErrorsView />
    return
  }
  if (page === Pages.actionSelect) {
    ModalSignal.value = <ActionSelect />
    return
  }
  if (page === Pages.processView) {
    ModalSignal.value = <ProcessView />
    return
  }
  if (page === Pages.fileDownload) {
    ModalSignal.value = <FileDownload />
    return
  }

  ModalSignal.value = <StepSelect />
}

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/success-circle.svg'
import { ReactComponent as ErrorIcon } from 'assets/icons/warning-outlined.svg'
import { LanguageSignal } from 'components/languageSelector/LanguageSelector'
import { Button } from 'components'
import { ButtonSize } from 'components/button/Button'
import { fileSignal } from 'components/processView/ProcessView'
import Upload from 'components/upload/Upload'
import { ReactElement, useState } from 'react'
import { navigateTo, Pages } from 'services/routes'
import { formatFileSize, isCorrectFile, isExcelFile, mergeUniqueFiles, validateFilelist } from 'services/upload'

export default function Anonymization(): ReactElement {
  const [files, setFilesToUpload] = useState<File[]>([])

  const handleUpload = (fl: File[]) => {
    const uniques = mergeUniqueFiles(files, fl)
    setFilesToUpload(uniques)
  }

  const removeFile = (file: File, index: number) => () => {
    const newFiles = files.filter((f, i) => f.name !== file.name && index !== i)
    setFilesToUpload(newFiles)
  }

  const getStatusIcon = (file: File) => {
    if (isExcelFile(file)) {
      return <SuccessIcon />
    }
    return <ErrorIcon width={33} height={33} fill="#DA4238" />
  }

  const submitForm = () => {
    fileSignal.value = files.find(file => isCorrectFile(file))
    navigateTo(Pages.processView)
  }

  return (
    <>
      <h3 className="text-2xl font-extrabold text-black">
        {LanguageSignal.value.terms.Pages.Upload.uploadFile}
      </h3>
      {/* eslint-disable-next-line react/no-danger */}
      <p
        className="text-[#5A5A5A]"
        dangerouslySetInnerHTML={{ __html:
        LanguageSignal.value.terms.Pages.Anonymization.selectData }}
      />
      <Upload onUploadReady={handleUpload} />

      {!!files.length && (
      <div className="flex w-full flex-col gap-2">
        {files.map((file, index) => (
          <div
            key={crypto.randomUUID()}
            className="flex justify-between
            rounded-lg
            border border-[#E6E6E6] p-4"
          >

            <div className="flex max-w-[95%] items-center justify-between">
              <div className="flex items-center">
                {getStatusIcon(file)}
              </div>

              <div className="ml-2 flex max-w-[calc(100%-40px)] flex-col items-start">
                <p title={file.name} className="w-full truncate pr-px text-right font-black text-black">
                  {file.name}
                </p>
                <p className="text-right font-normal">
                  {formatFileSize(file.size)}
                </p>
              </div>
            </div>
            <div>
              <button
                type="button"
                onClick={removeFile(file, index)}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        ))}
      </div>
      ) }

      <Button
        size={ButtonSize.medium}
        text={LanguageSignal.value.terms.Pages.Anonymization.anonymizeFile}
        disabled={!validateFilelist(files)}
        onClick={submitForm}
      />

      <button
        className="font-extrabold text-black underline"
        type="button"
        onClick={() => navigateTo(Pages.actionSelect)}
      >
        {LanguageSignal.value.terms.Common.leave}
      </button>

    </>
  )
}

import { ReactElement } from 'react'
import { signal } from '@preact/signals-react'

import './SideMenu.scss'

/**
 * Signal used to control the side menu open state
 */
export const SideMenuOpenedSignal = signal<boolean>(false)

function SideMenu(): ReactElement {
  return (
    <div data-testid="side-menu" className={`side-menu${SideMenuOpenedSignal.value ? ' opened' : ''}`} />
  )
}

export default SideMenu

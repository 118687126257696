import { ReactComponent as RightIcon } from 'assets/icons/right.svg'
import { LanguageSignal } from 'components/languageSelector/LanguageSelector'
import { ReactElement } from 'react'
import { navigateTo, Pages } from 'services/routes'

export default function ActionSelect(): ReactElement {
  const ACTIONS = [{
    id: 'upload',
    name: LanguageSignal.value.terms.Pages.ActionSelect.newUpload,
  },
  {
    id: 'history',
    name: LanguageSignal.value.terms.Pages.ActionSelect.uploadHistory,
  },
  ]
  const handleAction = (id: string) => {
    if (id === 'upload') {
      navigateTo(Pages.stepSelect)
    }
  }

  return (
    <>
      <h3 className="text-2xl font-extrabold text-black">
        {LanguageSignal.value.terms.Common.welcome}
      </h3>

      <p className="text-[#5A5A5A]">{LanguageSignal.value.terms.Pages.ActionSelect.selectOne}</p>

      <div className="flex flex-col gap-4">
        {ACTIONS.map(option => (
          <li key={option.id} className="list-none">
            <button
              type="button"
              className={`m-[2px] 
              flex h-[96px] w-full  items-center
              rounded-xl border  px-4 shadow-[0px_2px_8px_0px_#0000000D]
              ${option.id === 'history' ? 'cursor-not-allowed opacity-40' : 'cursor-pointer'}`}
              onClick={() => handleAction(option.id)}
              disabled={option.id === 'history'}
            >
              <div className="flex w-full items-center justify-between">
                <span className="text-xl font-extrabold text-[#050D12]">{option.name}</span>
                <span><RightIcon /></span>
              </div>
            </button>
          </li>
        ))}

      </div>
    </>
  )
}

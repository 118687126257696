/* eslint-disable max-len */
export const termsEn = {
  Common: {
    loading: 'Loading',
    updated: 'Updated',
    sortBy: 'Sort by',
    cancel: 'Cancel',
    save: 'Save',
    continue: 'Continue',
    leave: 'Leave',
    welcome: 'Welcome to I-RH',
    or: 'Or',
    logOut: 'Log out',
  },
  Error: {
    common: {
      errors: 'Errors',
      seeAll: 'View errors',
    },
    forbidden: {
      appTitle: 'You do not have the necessary rights to access this application.',
      appSubtitle: 'Please contact your administrator.',
      content: 'You do not have the rights to access this resource. Please try again or contact your administrator.',
      exitButton: 'Return to the application portal',
    },
    httpStatus: {
      forbidden: 'You do not have the rights to perform this action.',
      notFound: 'The requested resource does not exist.',
      internalServerError: 'Error processing the request on the server side.',
      badGateway: 'The server is unavailable.',
      unknownError: 'An unknown error occurred.',
      badRequest: 'Check the information you entered and try again.',
    },
    anonymization: {
      moduleNotLoaded: 'An error occurred while loading the anonymization module, please reload the page.',
      unknown: 'The file could not be anonymized.',
      missingSheets: (sheet: string) => `Error during anonymization: missing sheet ${sheet}`,
      unknownSheets: (sheet: string) => `Error during anonymization: unknown sheet ${sheet}`,
      missingColumns: (sheet: string, column: string) => `Error during anonymization: missing column ${column} in sheet ${sheet}`,
      unknownColumns: (sheet: string, column: string) => `Error during anonymization: unknown column ${column} in sheet ${sheet}`,
      cellErrors: (sheet: string, column: string, row: string, content: string) => `Error during anonymization: invalid cell format (sheet ${sheet}, row ${row}, column ${column}, "${content}")`,
    },
  },
  SideMenu: {
    links: {
      myViz: 'My visualizations',
      help: 'Request assistance',
    },
  },
  Pages: {
    ActionSelect: {
      newUpload: 'New upload',
      uploadHistory: 'Upload history',
      selectOne: 'Please select the action to perform',
    },
    Upload: {
      uploadFormat: 'Expected format:<br /><span>.xlsx</span>',
      dragAndDrop: 'Drag and drop your files here',
      uploadFile: 'File upload',
      selectFile: 'Choose a file',
      acceptedExtensions: 'Expected format(s):',
      sizeLimit: 'Maximum size:',
      bytes: 'bytes',
      kiloBytes: 'KB',
      megaBytes: 'MB',
      gigaBytes: 'GB',
    },
    Anonymization: {
      anonymizeFile: 'Anonymize data',
      selectData: 'Please upload the data to anonymize',
      running: 'Data anonymization in progress',
      failed: 'Anonymization failed',
      success: 'Anonymization completed',
      anonymize: 'Anonymization',
      fileName: 'anonymized_file',
    },
    StepSelection: {
      collect: 'Collection',
      onlyCollect: 'I want to automatically collect the data',
      format: 'Formatting',
      hasData: 'I already have my data',
      anonymize: 'Anonymization',
      hasFormattedData: 'I already have my data, and it is properly formatted',
      selectStep: 'Which step would you like to start at?',
    },
    FileDownload: {
      fileViewing: 'Viewing files before upload',
      upload: 'Upload the file to the IS',
    },
  },
  Modals: {},
  Messages: {
    success: {},
    errors: {},
  },
} as const

export default termsEn

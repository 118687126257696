/* eslint-disable react/no-danger */
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg'
import { LanguageSignal } from 'components/languageSelector/LanguageSelector'
import { Button } from 'components'
import { ButtonSize, ButtonStyle } from 'components/button/Button'
import { ChangeEvent, DragEvent, useRef } from 'react'
import { ALLOWED_EXTENSIONS, formatFileSize, MAX_UPLOAD_SIZE } from 'services/upload'

type Props = {
  onUploadReady: (files: File[]) => void
}

export default function Upload({ onUploadReady }: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleUpload = async (files: File[]) => {
    fileInputRef.current.value = ''

    return onUploadReady(files)
  }

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const { items } = event.dataTransfer
    const files = Array.from(items).map(item => item.getAsFile())
    return handleUpload(files as File[])
  }

  const handleInputFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    handleUpload(Array.from(event.target.files as FileList))
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div className="flex flex-col">
      <div
        className="flex-column-center
        hover:shadow-[0 0 8px rgba(5,13,18,.161)] w-full cursor-pointer gap-3 rounded-lg
         border border-dashed border-[#256EFF] p-6 text-center transition-shadow duration-200"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleClick}
        role="button"
        tabIndex={0}
      >
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleInputFileChange}
          style={{ display: 'none' }}
          multiple
          accept={ALLOWED_EXTENSIONS.join(', ')}
        />

        <UploadIcon />

        <div
          className="[&>.simple-button]:bg-red-50] flex flex-col justify-center text-sm font-normal
            text-[#212731]"
        >

          <div className="mb-4 flex flex-col gap-2">
            <span>{LanguageSignal.value.terms.Pages.Upload.dragAndDrop}</span>
            <span className="text-sm">{LanguageSignal.value.terms.Common.or}</span>
          </div>

          <Button
            size={ButtonSize.medium}
            text={LanguageSignal.value.terms.Pages.Upload.selectFile}
            style={ButtonStyle.alternative}
            icon={<UploadIcon width={24} height={24} />}
          />

          <div className="mt-2 flex flex-col text-[#212731]">
            <span>
              {LanguageSignal.value.terms.Pages.Upload.acceptedExtensions}
              <span className="text-[#256eff]">{ALLOWED_EXTENSIONS.join(' ')}</span>
            </span>

            <span>
              {LanguageSignal.value.terms.Pages.Upload.sizeLimit}
              <span className="text-[#256eff]">
                {formatFileSize(MAX_UPLOAD_SIZE)}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

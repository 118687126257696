import { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react'
import './LanguageSelector.scss'
import termsEn from 'assets/localization/termsEn'
import { ReactComponent as FrenchIcon } from 'assets/icons/french.svg'
import { ReactComponent as EnglishUKIcon } from 'assets/icons/englishUK.svg'
import { signal } from '@preact/signals-react'
import { Language } from 'types/lang'
import terms from 'assets/localization/terms'

const LANGUAGES: Language[] = [{ language: 'English', terms: termsEn, id: 'en' },
  { language: 'Français', terms, id: 'fr' },
]

export const LanguageSignal = signal<Language>(
  LANGUAGES.find(lang => lang.id === localStorage.getItem('language')) || { language: 'Français', terms, id: 'fr' },
)

export default function LanguageSelector(): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const handleButtonClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget)
  }
  const handleMenuClose = (next?: () => void) => {
    setAnchorEl(null)
    if (next !== undefined) next()
  }
  const handleClickOutside = (event: Event) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      handleMenuClose()
    }
  }
  // Close the menu when the user clicks outside of the menu
  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isMenuOpen])

  const changeLanguage = (item: Language) => {
    handleMenuClose()
    localStorage.setItem('language', item.id)
    LanguageSignal.value = item
  }

  const getIcon = (id: string) => {
    if (id === 'fr') {
      return <FrenchIcon className="rounded-sm border border-solid border-white" />
    }

    if (id === 'en') {
      return <EnglishUKIcon className="rounded-sm border border-solid border-white" />
    }

    return <EnglishUKIcon />
  }

  return (
    <div ref={menuRef} className={`menu-account${isMenuOpen ? ' open' : ''}`}>
      <button
        type="button"
        onClick={handleButtonClick}
        className="menu-account-button"
      >
        {getIcon(LanguageSignal.value.id)}
      </button>
      {isMenuOpen && (
        <div className="menu-account-list">
          <ul>
            {LANGUAGES.map(item => (
              <li key={item.language}>
                <button type="button" onClick={() => changeLanguage(item)}>
                  <div className="menu-item-label">
                    {getIcon(item.id)}
                    <span className="text-black">{item.language}</span>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

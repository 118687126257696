import { ReactElement } from 'react'

import './Button.scss'

export enum ButtonStyle {
  primary = 'primary',
  secondary = 'secondary',
  light = 'light',
  borderLess = 'borderLess',
  borderLessPrimary = 'borderLessPrimary',
  borderLessWhite = 'borderLessWhite',
  delete = 'delete',
  alternative = 'alternative'
}

export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
}

type Props = {
  text: string;
  onClick?: () => void;
  style?: ButtonStyle;
  disabled?: boolean;
  icon?: ReactElement;
  size?: ButtonSize;
}

export default function SimpleButton({
  text,
  onClick,
  style = ButtonStyle.primary,
  size = ButtonSize.small,
  disabled = false,
  icon = null,
}: Props): ReactElement {
  const classes = [
    'simple-button',
    `button--${size}`,
    `button--${style}`,
    style.includes('borderLess') && 'is-borderLess',
    disabled && 'disabled',
  ].filter(Boolean).join(' ')

  return (
    <button
      type="button"
      className={classes}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {text}
    </button>
  )
}

import { ReactElement } from 'react'
import { ReactComponent as ErrorIcon } from 'assets/icons/warning-outlined.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/check.svg'
import { Button } from 'components'
import { ButtonSize, ButtonStyle } from 'components/button/Button'
import { LanguageSignal } from 'components/languageSelector/LanguageSelector'
import { navigateTo, Pages } from 'services/routes'
import { proccessedFilesErrorsSignal } from 'components/processView/ProcessView'

type Props = {
  type: 'success' | 'error';
}
// todo handle success
export default function Feedback({ type }: Props): ReactElement {
  // todo move/refacto
  const headerColor = type === 'success' ? 'green' : '#FDDCDA'
  const btnStyle = type === 'success' ? ButtonStyle.borderLess : ButtonStyle.delete

  const getIcon = () => {
    if (type === 'success') {
      return <SuccessIcon />
    }

    return <ErrorIcon width={72} height={62} fill="#DA4238" />
  }

  const getBtnText = () => {
    if (type === 'success') {
      return LanguageSignal.value.terms.Common.leave
    }

    return LanguageSignal.value.terms.Error.common.seeAll
  }

  const handleClick = () => {
    if (type === 'error') {
      navigateTo(Pages.errorsView)
    }
  }

  const leavePage = () => {
    proccessedFilesErrorsSignal.value = undefined
    navigateTo(Pages.actionSelect)
  }

  return (
    <>
      <div className="w-100 flex h-28 items-center justify-center rounded-2xl bg-[#FDDCDA]">
        {getIcon()}
      </div>

      <div className="flex justify-center">
        <p className="text-xl font-extrabold">{LanguageSignal.value.terms.Error.anonymization.unknown}</p>
      </div>

      <Button
        size={ButtonSize.medium}
        text={getBtnText()}
        style={btnStyle}
        onClick={handleClick}
      />

      <button
        className="font-extrabold text-black underline"
        type="button"
        onClick={leavePage}
      >
        {LanguageSignal.value.terms.Common.leave}
      </button>
    </>
  )
}

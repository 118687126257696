/* eslint-disable max-len */
export const terms = {
  Common: {
    loading: 'Chargement',
    updated: 'Modifié',
    sortBy: 'Trier par',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    continue: 'Continuer',
    leave: 'Quitter',
    welcome: 'Bienvenue dans I-RH',
    or: 'Ou',
    logOut: 'Déconnexion',
  },
  Error: {
    common: {
      errors: 'Erreurs',
      seeAll: 'Voir les erreurs',
    },
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    httpStatus: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Erreur de traitement de la requête côté serveur.',
      badGateway: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
    },
    anonymization: {
      moduleNotLoaded: "Une erreur au lieu lors du chargement du module d'anonymisation, veuillez recharger la page",
      unknown: "Le fichier n'a pas pu être anonymisé",
      missingSheets: (sheet: string) => `Erreur lors de l'anonymisation : feuille ${sheet} manquante`,
      unknownSheets: (sheet: string) => `Erreur lors de l'anonymisation : feuille ${sheet} inconnue`,
      missingColumns: (sheet: string, column: string) => `Erreur lors de l'anonymisation : colonne ${column} de la feuille ${sheet} manquante`,
      unknownColumns: (sheet: string, column: string) => `Erreur lors de l'anonymisation : colonne ${column} de la feuille ${sheet} inconnue`,
      cellErrors: (sheet:string, column: string, row: string, content: string) => `Erreur lors de l'anonymisation : mauvais format de la cellule (feuille ${sheet}, ligne ${row}, colonne ${column}, "${content}")`,
    },
  },
  SideMenu: {
    links: {
      myViz: 'Mes visualisations',
      help: 'Demande d’assistance',
    },
  },
  Pages: {
    ActionSelect: {
      newUpload: 'Nouvel upload',
      uploadHistory: 'Historique des uploads',
      selectOne: "Veuillez sélectionner l'action à effectuer",
    },
    Upload: {
      uploadFormat: 'Format attendu :<br /><span>.xlsx</span>',
      dragAndDrop: 'Glissez-déposez vos fichiers ici',
      uploadFile: 'Import du fichier',
      selectFile: 'Choissisez un fichier',
      acceptedExtensions: 'Format(s) attendu(s) : ',
      sizeLimit: 'Taille maximale : ',
      bytes: 'octest',
      kiloBytes: 'Ko',
      megaBytes: 'Mo',
      gigaBytes: 'Go',
    },
    Anonymization: {
      anonymizeFile: 'Anonymiser les données',
      selectData: 'Veuillez importer les données à anonymiser',
      running: 'Anonymisation des données en cours',
      failed: 'Anonymisation échouée',
      success: 'Anonymisation effectuée',
      anonymize: 'Anonymisation',
      fileName: 'fichier_anonymise',
    },
    StepSelection: {
      collect: 'Collecte',
      onlyCollect: 'je souhaite collecter automatiquement les données',
      format: 'Formatage',
      hasData: "j'ai déjà mes données",
      anonymize: 'Anonymisation',
      hasFormattedData: "j'ai déjà mes données et elles sont correctement formatées",
      selectStep: 'A quelle étape souhaitez-vous commencer ?',
    },
    FileDownload: {
      fileViewing: 'Visualisation des fichiers avant upload',
      upload: 'Uploader le fichier vers le SI',
    },
  },
  Modals: {},
  Messages: {
    success: {
    },
    errors: {
    },
  },
} as const

export default terms

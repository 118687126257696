import { signal } from '@preact/signals-react'

import './ModalWrapper.scss'

/**
 * Signal used to communicate with modals
 * which are responsible for displaying/hiding the modal
*/
export const ModalSignal = signal<React.ReactNode>(undefined)

export default function ModalWrapper() {
  return (
    <div className={`modal-wrapper ${ModalSignal.value ? ' displayed' : ''}`}>
      <div className="modal">
        {ModalSignal.value}
      </div>
      <div className="background" />
    </div>
  )
}

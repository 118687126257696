import { ReactComponent as InfoIcon } from 'assets/icons/info-outlined.svg'
import { LanguageSignal } from 'components/languageSelector/LanguageSelector'
import { Button } from 'components'
import { ButtonSize } from 'components/button/Button'
import { proccessedFilesErrorsSignal } from 'components/processView/ProcessView'
import { ReactElement } from 'react'
import { navigateTo, Pages } from 'services/routes'

export default function ErrorsView(): ReactElement {
  const leavePage = () => {
    proccessedFilesErrorsSignal.value = undefined
    navigateTo(Pages.actionSelect)
  }

  return (
    <>
      <h3 className="text-2xl font-extrabold text-black">
        {LanguageSignal.value.terms.Error.common.errors}
      </h3>

      <div className="flex flex-col gap-2">
        {proccessedFilesErrorsSignal.value.map(error => (
          <div
            key={crypto.randomUUID()}
            className=" flex min-h-9 items-center justify-between rounded bg-[#FBF4F4] py-[6px] pl-2 pr-[6px]"
          >
            <p className="max-w-[calc(100%-24px)] text-[#1F1F1F]">{error}</p>

            <InfoIcon width={24} height={24} fill="#DA4238" />
          </div>
        ))}
      </div>

      <div className="flex justify-center">
        <div className="w-3/5 [&>button]:w-full">
          <Button
            size={ButtonSize.medium}
            text={LanguageSignal.value.terms.Common.leave}
            onClick={leavePage}
          />
        </div>
      </div>
    </>
  )
}

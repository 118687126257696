import { signal } from '@preact/signals-react'
import { ReactComponent as SuccessIcon } from 'assets/icons/success-circle.svg'
import { ReactComponent as ErrorIcon } from 'assets/icons/warning-outlined.svg'
import { LanguageSignal } from 'components/languageSelector/LanguageSelector'
import { Button, Loader } from 'components'
import { ButtonSize } from 'components/button/Button'
import { ReactElement, useEffect } from 'react'
import { navigateTo, Pages } from 'services/routes'

export const fileSignal = signal<File>(undefined)
export const proccessedFilesSignal = signal<{name: string, link: string}[]>(undefined)
export const proccessedFilesErrorsSignal = signal<string[]>(undefined)
export const piodydeSignal = signal<any>(undefined)
// todo typing, export fct, refactor

export default function ProcessView(): ReactElement {
  const anonymizeFile = async () => {
    const file = fileSignal.value
    const fileName = file.name
    const fileExtension = fileName.split('.').pop().toLowerCase() // Extension du fichier

    const fileArrayBuffer = await file.arrayBuffer()

    const anonymizeModule = piodydeSignal.value.pyimport('irh_processing.anonymize.anonymize_excel')

    let result
    try {
      if (fileExtension === 'xls') {
        const fileBytes = piodydeSignal.value.toPy(new Uint8Array(fileArrayBuffer))
        result = await anonymizeModule.anonymize_xls_file(fileBytes)
      } else if (fileExtension === 'xlsx') {
        const fileBytes = piodydeSignal.value.toPy(new Uint8Array(fileArrayBuffer))
        result = await anonymizeModule.anonymize_xlsx_file(fileBytes)
      }

      let jsResult

      if (result && typeof result.toJs === 'function') {
        jsResult = result.toJs()
      } else {
        jsResult = result
      }

      if (jsResult instanceof Uint8Array) {
        const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const outputFileName = `${LanguageSignal.value.terms.Pages.Anonymization.fileName}.xlsx`

        const anonymizedBlob = new Blob([jsResult], { type: mimeType })

        proccessedFilesSignal.value = [{ name: outputFileName, link: URL.createObjectURL(anonymizedBlob) }]
        return
      }

      if (typeof jsResult === 'string') {
        try {
          const errorDict = JSON.parse(jsResult)
          const errors = []

          Object.entries(errorDict).forEach(([key, value]) => {
            if (key === 'missing_sheets') {
              const val = value as string[]
              val.forEach(v => (
                errors.push(LanguageSignal.value.terms.Error.anonymization.missingSheets(v))
              ))

              return
            }

            if (key === 'unknown_sheets') {
              const val = value as string[]

              val.forEach(v => (
                errors.push(LanguageSignal.value.terms.Error.anonymization.unknownSheets(v))
              ))
            }

            if (key === 'missing_columns') {
              const val = value as {[key:string]: string[]}
              Object.entries(val).forEach(([k, v]) => {
                v.forEach(col => (
                  errors.push(LanguageSignal.value.terms.Error.anonymization.missingColumns(col, k))
                ))
              })
            }

            if (key === 'unknown_columns') {
              const val = value as {[key:string]: string[]}
              Object.entries(val).forEach(([k, v]) => {
                v.forEach(col => (
                  errors.push(LanguageSignal.value.terms.Error.anonymization.unknownColumns(col, k))
                ))
              })
            }

            if (key === 'cell_errors') {
              const val = value as {[key:string]: {row: number, column: string, error: string, value: unknown}[]}
              Object.entries(val).forEach(([sheet, v]) => {
                v.forEach(cell => (
                  errors.push(LanguageSignal.value.terms.Error.anonymization.cellErrors(
                    sheet,
                    cell.column,
                    cell.row.toString(),
                    cell.value.toString(),
                  ))
                ))
              })
            }
          })
          proccessedFilesErrorsSignal.value = errors
        } catch (error) {
          proccessedFilesErrorsSignal.value = ['Erreur de parsing du JSON : ', error]
        }
      }
    } catch (error) {
      proccessedFilesErrorsSignal.value = ['Erreur de parsing du JSON : ', error]
    }
  }

  useEffect(() => {
    if (piodydeSignal.value) {
      anonymizeFile()
    }
  }, [piodydeSignal.value])

  const handleResults = () => {
    // to do refcto

    // success
    if (proccessedFilesSignal.value && 'link' in proccessedFilesSignal.value[0]) {
      navigateTo(Pages.fileDownload)
      return
    }
    // failure
    if (proccessedFilesErrorsSignal.value) {
      navigateTo(Pages.errors)
    }
  }

  const anonymizationDone = () => proccessedFilesSignal.value || proccessedFilesErrorsSignal.value

  const getAnonymizationStatus = () => {
    if (proccessedFilesSignal.value) {
      return (
        <>
          {LanguageSignal.value.terms.Pages.Anonymization.success}
          <SuccessIcon width={24} height={24} />
        </>
      )
    }

    if (proccessedFilesErrorsSignal.value) {
      return (
        <>
          {LanguageSignal.value.terms.Pages.Anonymization.failed}
          <ErrorIcon width={24} height={24} fill="#DA4238" />
        </>
      )
    }

    return LanguageSignal.value.terms.Pages.Anonymization.running
  }

  const getButtonText = () => {
    if (anonymizationDone()) {
      return LanguageSignal.value.terms.Common.continue
    }

    return LanguageSignal.value.terms.Common.loading
  }
  return (
    <>
      <h3 className="text-2xl font-extrabold text-black">{LanguageSignal.value.terms.Pages.Anonymization.anonymize}</h3>

      <div className={`${!anonymizationDone() ? 'italic' : ''} 
      flex items-center gap-3 text-[#5A5A5A]`}
      >
        {getAnonymizationStatus()}
      </div>

      <div className="relative inline-block [&>button]:w-full">
        <Button
          size={ButtonSize.medium}
          text={getButtonText()}
          disabled={!anonymizationDone()}
          onClick={handleResults}
        />

        {!anonymizationDone() && (
        <div className="pointer-events-none absolute left-0
         top-0 flex size-full items-center justify-center
         bg-white/50 [&_.spinner]:bg-[conic-gradient(#0000_10%,_black)]
         "
        >
          <Loader variant="small" />
        </div>
        )}

      </div>
    </>
  )
}
